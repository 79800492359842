import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Atom } from "react-loading-indicators";
import { ResponsiveContainer } from 'recharts';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Function to format number as USD with commas and no cents
const formatUSD = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

// Function to format the multiples displayed in the x-axis ticks
const formatMultiple = (value) => {
  return value.toFixed(1) + 'x';
};

const Valuations = () => {
  const [businessName, setBusinessName] = useState('');
  const [yearEstablished, setYearEstablished] = useState('');
  const [ltmRevenue, setLtmRevenue] = useState('');
  const [ltmRevenueWeight, setLtmRevenueWeight] = useState(3);
  const [ltmEbitda, setLtmEbitda] = useState('');
  const [ltmEbitdaWeight, setLtmEbitdaWeight] = useState(3);
  const [revenueYoyGrowth, setRevenueYoyGrowth] = useState('');
  const [revenueYoyGrowthWeight, setRevenueYoyGrowthWeight] = useState(3);
  const [description, setDescription] = useState('');
  const [numPrecedentComps, setNumPrecedentComps] = useState('');
  const [numPublicComps, setNumPublicComps] = useState('');
  const [forcedMultipleType, setForcedMultipleType] = useState('None');
  const [forcedMultipleValue, setForcedMultipleValue] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [precedentCompsData, setPrecedentCompsData] = useState([]);
  const [publicCompanyCompsData, setPublicCompanyCompsData] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
// const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // State to manage loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const data = context.raw;
            return [
              `Lower Bound: ${formatUSD(data[0])}`,
              `Upper Bound: ${formatUSD(data[1])}`
            ];
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: false,
        },
        ticks: {
          color: 'black',
          callback: function(value) {
            return formatUSD(value);
          }
        }
      },
      x: {
        title: {
          display: false,
        },
        ticks: {
          color: 'black',
          callback: function(value, index) {
            return xAxisLabels[index];
          }
        }
      }
    },
  };

  useEffect(() => {
    if (responseData && responseData.Valuations) {
      console.log(responseData)
      const valuations = responseData.Valuations;

      const ranges = valuations.valuation_range_dict

      const allValues = [
        ranges.indicative_range_low, ranges.indicative_range_high,
        ranges.precedent_comps_low, ranges.precedent_comps_high,
        ranges.public_company_comps_low, ranges.public_company_comps_high
      ];
      const minValue = Math.min(...allValues);
      const maxValue = Math.max(...allValues);

      const yMin = Math.floor((minValue * 0.8) / 1000000) * 1000000;
      const yMax = Math.ceil((maxValue * 1.2) / 1000000) * 1000000;

      setChartData({
        labels: ['Indicative Range', 'Precedent Comps', 'Public Company Comps'],
        datasets: [
          {
            label: 'Valuation Range',
            data: [
              [ranges.indicative_range_low, ranges.indicative_range_high],
              [ranges.precedent_comps_low, ranges.precedent_comps_high],
              [ranges.public_company_comps_low, ranges.public_company_comps_high],
            ],
            backgroundColor: 'rgba(200, 220, 240, 1)'
          },
        ],
      });

      setXAxisLabels([
        `Indicative Range\n(${formatMultiple(ranges.indicative_range_low_multiple)} - ${formatMultiple(ranges.indicative_range_high_multiple)})`,
        `Precedent Comps\n(${formatMultiple(ranges.precedent_comps_low_multiple)} - ${formatMultiple(ranges.precedent_comps_high_multiple)})`,
        `Public Company Comps\n(${formatMultiple(ranges.public_company_comps_low_multiple)} - ${formatMultiple(ranges.public_company_comps_high_multiple)})`
      ]);

      // Ensure precedent_comps is an array before setting precedentCompsData
      if (Array.isArray(valuations.precedent_comps)) {
        setPrecedentCompsData(valuations.precedent_comps);
      } else {
        console.error('precedent_comps is not an array:', valuations.precedent_comps);
        setPrecedentCompsData([]);
      }

      // Ensure public_company_comps is an array before setting publicCompanyCompsData
      if (Array.isArray(valuations.public_company_comps)) {
        setPublicCompanyCompsData(valuations.public_company_comps);
      } else {
        console.error('public_company_comps is not an array:', valuations.public_company_comps);
        setPublicCompanyCompsData([]);
      }

      setSubmitted(true);
    }
  }, [responseData]);



  const plugins = [{
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.font = '12px Arial';

          // Low value - positioned below the bar
          ctx.textBaseline = 'top';
          ctx.fillText(formatUSD(data[0]), bar.x, bar.y + bar.height + 5);

          // High value - positioned above the bar
          ctx.textBaseline = 'bottom';
          ctx.fillText(formatUSD(data[1]), bar.x, bar.y - 5);
        });
      });
    }
  }];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show the loader
    try {
      const response = await axios.post('/api/submit', {
        businessName,
        yearEstablished: yearEstablished || null,
        description: description || null,
        ltmRevenue,
        ltmRevenueWeight,
        ltmEbitda: ltmEbitda || null,
        ltmEbitdaWeight,
        revenueYoyGrowth: revenueYoyGrowth || null,
        revenueYoyGrowthWeight,
        numPrecedentComps: numPrecedentComps || null,
        numPublicComps: numPublicComps || null,
        forcedMultipleType,
        forcedMultipleValue: forcedMultipleValue || null});

      setResponseData(response.data);
      setIsLoading(false); // Hide the loader

    } catch (error) {
      setIsLoading(false); // Hide the loader
      console.error('Error submitting data:', error);
    }
  };


    const exportData = async () => {
    setIsExporting(true);
    setIsLoadingExport(true); // Show the loader
    try {
      const response = await axios.post('/api/export_data', {
        precedent_comps: responseData.Valuations.precedent_comps,
        public_company_comps: responseData.Valuations.public_company_comps,
        company_dict: responseData.Valuations.company_dict
      }, {
        responseType: 'blob',
      });

      if (response.status === 200 && response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Modified_Comps_and_Football_Field.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Unexpected response:', response);
        // You might want to show an error message to the user here
        alert('Error exporting Excel file. Please try again.');
      }
    } catch (error) {
      console.error('Error exporting Excel file:', error);
      // Show an error message to the user
      alert('Error exporting Excel file. Please try again.');
    } finally {
      setIsLoadingExport(false); // hide the loader
      setIsExporting(false);
    }
  };

  return (
    <div style={styles.appContainer(submitted)}>
      <div style={styles.formContainer(submitted)}>
        <form onSubmit={handleSubmit} style={styles.form}>
          {/* General Business Information Section */}
          <h3 style={styles.headerText}>General Business Information</h3>

          {/* Business Name/ URL Input */}
          <p style={styles.descriptionText}>Business Name/ URL:</p>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            placeholder="Enter Business Name or URL"
            required
            style={styles.input}
          />

          {/* Year Established Input */}
          <p style={styles.descriptionText}>Year Established:</p>
          <input
            type="text"
            value={yearEstablished}
            onChange={(e) => setYearEstablished(e.target.value)}
            placeholder="Enter Year Established"
            style={styles.input}
          />

          {/* Business Description Input */}
          <p style={styles.descriptionText}>Business Description:</p>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Business Description (Optional)"
            style={styles.textArea}
          />

          {/* Financial Information Section */}
          <h3 style={styles.headerText}>Financial Information</h3>

          {/* LTM Revenue Input */}
          <p style={styles.descriptionText}>Last 12 Months Revenue:</p>
          <input
            type="text"
            value={ltmRevenue}
            onChange={(e) => setLtmRevenue(e.target.value)}
            placeholder="Enter Last 12 Months Revenue ($)"
            required
            style={styles.input}
          />
          <div style={styles.sliderContainer}>
            <label htmlFor="ltmRevenueWeight" style={styles.descriptionText}>LTM Revenue Weight:</label>
            <input
              type="range"
              id="rating"
              name="rating"
              min="1"
              max="5"
              value={ltmRevenueWeight}
              onChange={(e) => setLtmRevenueWeight(e.target.value)}
              style={styles.slider}
            />
            <div style={styles.sliderLabel}>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
            </div>
          </div>

          {/* LTM EBITDA Input */}
          <p style={styles.descriptionText}>Last 12 Months EBITDA:</p>
          <input
            type="text"
            value={ltmEbitda}
            onChange={(e) => setLtmEbitda(e.target.value)}
            placeholder="Enter Last 12 Months EBITDA ($)"
            style={styles.input}
          />
          <div style={styles.sliderContainer}>
            <label htmlFor="ltmEbitdaWeight" style={styles.descriptionText}>LTM EBITDA Weight:</label>
            <input
              type="range"
              id="rating"
              name="rating"
              min="1"
              max="5"
              value={ltmEbitdaWeight}
              onChange={(e) => setLtmEbitdaWeight(e.target.value)}
              style={styles.slider}
            />
            <div style={styles.sliderLabel}>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
            </div>
          </div>

          {/* Revenue YoY Growth Input */}
          <p style={styles.descriptionText}>Revenue YoY Growth:</p>
          <input
            type="text"
            value={revenueYoyGrowth}
            onChange={(e) => setRevenueYoyGrowth(e.target.value)}
            placeholder="Enter Revenue YoY Growth (%)"
            style={styles.input}
          />
          <div style={styles.sliderContainer}>
            <label htmlFor="revenueYoyGrowthWeight" style={styles.descriptionText}>Revenue YoY Growth Weight:</label>
            <input
              type="range"
              id="rating"
              name="rating"
              min="1"
              max="5"
              value={revenueYoyGrowthWeight}
              onChange={(e) => setRevenueYoyGrowthWeight(e.target.value)}
              style={styles.slider}
            />
            <div style={styles.sliderLabel}>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
            </div>
          </div>

          {/* Output Configuration Section */}
          <h3 style={styles.headerText}>Output Configuration Section</h3>

          {/* Number of Precedent Comps Input */}
          <p style={styles.descriptionText}>Number of Desired Precedent and Public Company Comps:</p>
          <input
            type="text"
            value={numPrecedentComps}
            onChange={(e) => setNumPrecedentComps(e.target.value)}
            placeholder="Desired Number of Precedent Comps"
            style={styles.input}
          />
          {/* Number of Public Company Comps Input */}
          <input
            type="text"
            value={numPublicComps}
            onChange={(e) => setNumPublicComps(e.target.value)}
            placeholder="Desired Number of Public Company Comps"
            style={styles.input}
          />

          {/* Forced Multiple Value Input */}
          <p style={styles.descriptionText}>Set Forced Multiple Type (Optional):</p>
          <div style={styles.selectContainer}>
            <select
              value={forcedMultipleType}
              onChange={(e) => setForcedMultipleType(e.target.value)}
              style={styles.select}
            >
              <option value="None">None</option>
              <option value="Revenue">Revenue</option>
              <option value="EBITDA">EBITDA</option>
            </select>
            <div style={styles.selectArrow}></div>
          </div>

          {/* Forced Multiple Value Input */}
          <p style={styles.descriptionText}>Forced Multiple Value:</p>
          <input
            type="text"
            value={forcedMultipleValue}
            onChange={(e) => setForcedMultipleValue(e.target.value)}
            placeholder="Enter Forced Multiple Value (Optional)"
            style={styles.input}
          />
          {isLoading && <div align="center"><Atom color="#32cd32" size="medium" text="Processing..." /></div>}
          <button type="submit" disabled={isLoading} style={styles.button}>Submit</button>
        </form>
      </div>

      {chartData && (
        <div style={styles.resultsContainer}>
          <h2 style={styles.resultsTitleText}>Valuation Results</h2>
          <p style={styles.valuationText}>
            Based on our analysis, the indicative valuation range for the business is estimated
            to be between <strong>{formatUSD(responseData.Valuations.valuation_range_dict.indicative_range_low)} </strong>
            and <strong>{formatUSD(responseData.Valuations.valuation_range_dict.indicative_range_high)}</strong>.
            This range takes into account various factors including financial performance, market conditions, and comparable company valuations.
          </p>


          <div style={styles.barChartStyle}>
            {/* Bar Chart */}
            <Bar options={options} data={chartData}  plugins={plugins}/>
          </div>


          <h3 style={styles.tableTitleText}>Precedent Transaction Comps</h3>
          {precedentCompsData.length > 0 ? (
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>Company</th>
                  <th style={styles.tableHeader}>Primary Industry</th>
                  <th style={styles.tableHeader}>Revenue</th>
                  <th style={styles.tableHeader}>EBITDA Multiple</th>
                  <th style={styles.tableHeader}>Data Source</th>
                </tr>
              </thead>
              <tbody>
                {precedentCompsData.map((company, index) => (
                  <tr key={index}>
                    <td style={styles.tableCell}>{company['Companies']}</td>
                    <td style={styles.tableCell}>{company['Primary Industry Code']}</td>
                    <td style={styles.tableCellNumeric}>{formatUSD(Math.round(company['Revenue']))}</td>
                    <td style={styles.tableCellMultiple}>{Math.round(company['Implied EVdivEBITDA'] * 10) / 10}x</td>
                    <td style={styles.tableCell}>{company['Data Source']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No company data available.</p>
          )}

          <h3 style={styles.tableTitleText}>Public Company Comps</h3>
          {publicCompanyCompsData.length > 0 ? (
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>Company</th>
                  <th style={styles.tableHeader}>Primary Industry</th>
                  <th style={styles.tableHeader}>Primary Geo</th>
                  <th style={styles.tableHeader}>Revenue</th>
                  <th style={styles.tableHeader}>EBITDA Multiple</th>
                  <th style={styles.tableHeader}>Data Source</th>
                </tr>
              </thead>
              <tbody>
                {publicCompanyCompsData.map((company, index) => (
                  <tr key={index}>
                    <td style={styles.tableCell}>{company['Companies']}</td>
                    <td style={styles.tableCell}>{company['Primary Industry Code']}</td>
                    <td style={styles.tableCell}>{company['Primary Geo']}</td>
                    <td style={styles.tableCellNumeric}>{formatUSD(Math.round(company['Revenue']))}</td>
                    <td style={styles.tableCellMultiple}>{Math.round(company['Implied EVdivEBITDA'] * 10) / 10}x</td>
                    <td style={styles.tableCell}>{company['Data Source']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No company data available.</p>
          )}

    {isLoadingExport && <div align="left"><Atom color="#32cd32" size="medium" text="Exporting..." /></div>}
	<button style={styles.button} disabled={isLoadingExport} onClick={exportData}>{'Export'}</button>

        </div>
      )}


    </div>
  );
};

// Styles
const styles = {
  pageContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10vh', // Start 10% down the page
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  appContainer: (submitted) => ({
    display: 'flex',
    justifyContent: submitted ? 'flex-start' : 'center',
    width: '100%',
    maxWidth: '100%',
    padding: '10px',
  }),
  formContainer: (submitted) => ({
    width: submitted ? '20%' : '100%',
    maxWidth: submitted ? 'none' : '400px',
    transition: 'all 0.5s ease',
    padding: '20px',
  }),
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionText: {
    fontFamily: 'Arial, sans-serif',
    margin: '1px 0',
    fontSize: '12px',
  },
  valuationText: {
    fontFamily: 'Arial, sans-serif',
    margin: '1px 0',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
    borderTop: '1px solid black',
    //borderBottom: '1px solid black',
    //backgroundColor: '#e6f2ff',
  },
  headerText: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '20px',
    borderBottom: '1px solid black',
  },
  input: {
    margin: '5px 0',
    padding: '7px',
    borderRadius: '4px',
    border: '1px solid #cccccc',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
  },
  textArea: {
    margin: '10px 0',
    padding: '10px',
    height: '100px',
    resize: 'vertical',
    borderRadius: '4px',
    border: '1px solid #cccccc',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
  },
  barChartStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust this value as needed
    width: '100%',
  },
  button: {
    margin: '10px 0',
    padding: '10px',
    backgroundColor: '#6487dc',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  tableContainer: {
    width: '75%',
    padding: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '5px',
  },
  tableHeader: {
    backgroundColor: '#e6f2ff',
    padding: '3px',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
  },
  tableCell: {
    padding: '3px',
    borderBottom: '1px solid #ddd',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
  },
  tableCellNumeric: {
    padding: '3px',
    borderBottom: '1px solid #ddd',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
    textAlign: 'right',
  },
  tableCellMultiple: {
    padding: '3px',
    borderBottom: '1px solid #ddd',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
    textAlign: 'center',
  },
  tableTitleText: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    marginTop: '15px',
    marginBottom: '10px',
    borderBottom: '1px solid black',
  },
  sliderContainer: {
    margin: '10px 0',
  },
  slider: {
    width: '100%',
    margin: '10px 0',
  },
  sliderLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
  },
  selectContainer: {
    position: 'relative',
    margin: '10px 0',
  },
  select: {
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    width: '100%',
    padding: '10px',
    paddingRight: '30px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '12px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
  },
  selectArrow: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #333',
    pointerEvents: 'none',
  },
  resultsContainer: {
    width: '80%',
    padding: '20px',
  },
  resultsTitleText: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center',
  },
};


export default Valuations;
