import React, { useState, useCallback, useEffect } from 'react';
// useState - Allows you to declare state variables and a function to update them
//            Returns an array containing: [The current state value, A function to update the state]
// useCallback - Prevents the function from being recreated on every render if its dependencies haven't changed
//               Improves performance by avoiding unnecessary re-renders of child components that rely on the function
// npm install react-dropzone papaparse axios
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import axios from 'axios';
import { LineChart, Line, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import * as XLSX from 'xlsx';
import { Atom } from "react-loading-indicators";

function Metrics() {
  // State variables
  const [headers, setHeaders] = useState([]); // Store CSV headers; initial state is empty array
  const [csvData, setCsvData] = useState(null); // Store full CSV data; initial state: null
  const [dropdownSelections, setDropdownSelections] = useState(Array(5).fill('')); // Store user dropdown selections; initial state: blank array of length 5
  const [showExportButton, setShowExportButton] = useState(false); // Handle the export to excel button
  const [activeTab, setActiveTab] = useState('data'); // Manage which tab is selected in the data display area
  const [chartData, setChartData] = useState(null); // House chart data
  const [selectedVariable, setSelectedVariable] = useState('MRR'); // Variable seen in chart, allows for user change
  const [selectedDate, setSelectedDate] = useState(null); // Allows for selection of end date to be shown on the chart; init null
  const [displayData, setDisplayData] = useState(null); // Data displayed on chart
  const [pivotData, setPivotData] = useState(null); // Data in pivot format to be exported to excel
  const [uploadedFiles, setUploadedFiles] = useState([]); // Track uploaded files
  const [columnStats, setColumnStats] = useState({}); // Store NaN counts for each column
  const [combinedData, setCombinedData] = useState([]); // New state for combined data
  const [isProcessing, setIsProcessing] = useState(false); // Loading state for processing

  // Store pie chart data
  const [recurringData, setRecurringData] = useState(null);
  const [nonRecurringData, setNonRecurringData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);

  // Store stacked bar chart data
  const [stackedBarData, setStackedBarData] = useState(null);

  // State to manage loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  // Helper function to get user-friendly descriptions of missing value types
  const getBreakdownDescription = (type) => {
    switch(type) {
      case 'empty': return 'Empty cells';
      case 'blank': return 'Whitespace only';
      case 'null': return '"null" values';
      case 'nan': return '"NaN" values';
      case 'na': return '"N/A" values';
      case 'undefined': return '"undefined" values';
      default: return type;
    }
  };

  // Function to calculate missing values
  const calculateMissingCount = useCallback((columnIndex, data) => {
    if (!data || !data.length) return { total: 0, breakdown: {} };
    
    const breakdown = {
      empty: 0,
      blank: 0,
      null: 0,
      nan: 0,
      na: 0,
      undefined: 0,
    };

    const total = data.reduce((count, row) => {
      if (!row || columnIndex >= row.length) return count;
      
      const value = row[columnIndex];
      
      // Handle undefined or null
      if (value === undefined || value === null) {
        breakdown.empty++;
        return count + 1;
      }

      // Convert to string and trim
      const strValue = String(value).trim().toLowerCase();
      
      // Handle empty strings
      if (value === '' || strValue === '') {
        breakdown.empty++;
        return count + 1;
      }

      // Handle whitespace only
      if (value.length > 0 && value.trim() === '') {
        breakdown.blank++;
        return count + 1;
      }

      // Handle various null/na/undefined strings
      if (strValue === 'null') {
        breakdown.null++;
        return count + 1;
      }
      if (strValue === 'nan') {
        breakdown.nan++;
        return count + 1;
      }
      if (['n/a', 'na'].includes(strValue)) {
        breakdown.na++;
        return count + 1;
      }
      if (strValue === 'undefined') {
        breakdown.undefined++;
        return count + 1;
      }
      
      return count;
    }, 0);

    return { total, breakdown };
  }, []);

  // Callback function for when a file is dropped or selected (used in drop-zone function below)
  const onDrop = useCallback((acceptedFiles) => {
    setIsProcessing(true);
    let processedFiles = 0;
    let newCombinedData = [...combinedData]; // Start with existing data
    const sortedFiles = acceptedFiles.sort((a, b) => a.name.localeCompare(b.name));

    sortedFiles.forEach(file => {
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            let fileHeaders = result.data[0];
            // Remove empty rows and ensure no trailing empty row
            let fileData = result.data.slice(1).filter(row => 
              row.length === fileHeaders.length && 
              row.some(cell => cell !== undefined && cell !== null && cell !== '')
            );

            // For first file ever uploaded
            if (headers.length === 0) {
              setHeaders(fileHeaders);
            } 
            // For subsequent files, verify headers match
            else if (JSON.stringify(fileHeaders) !== JSON.stringify(headers)) {
              alert(`File "${file.name}" has different headers and will be skipped.`);
              processedFiles++;
              if (processedFiles === sortedFiles.length) {
                setIsProcessing(false);
              }
              return;
            }

            // Add new file data to combined data
            newCombinedData = [...newCombinedData, ...fileData];
            setUploadedFiles(prev => [...prev, file.name]);
            
            processedFiles++;
            if (processedFiles === sortedFiles.length) {
              // Update all state at once after all files are processed
              setCombinedData(newCombinedData);
              setCsvData(newCombinedData);
              setIsProcessing(false);
            }
          }
        },
        header: false,
        skipEmptyLines: false,
        error: (error) => {
          console.error('Error parsing file:', error);
          alert(`Error parsing file "${file.name}". Please check the file format.`);
          setIsProcessing(false);
        }
      });
    });
  }, [combinedData, headers]); // Add headers as dependency

  // Set up the dropzone with react-dropzone; accept only csv
  // getRootProps - Adds event listeners for drag enter, drag over, drag leave, and drop events to the element
  // getInputProps - Provides props for the input element, including the ability to trigger a file selection dialog when clicked
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop, 
    accept: '.csv',
    multiple: true // Enable multiple file selection
  });

  // Add file list display component
  const FileList = () => (
    <div style={fileListStyle}>
      <h4>Uploaded Files ({uploadedFiles.length}):</h4>
      <ul>
        {uploadedFiles.map((fileName, index) => (
          <li key={index} style={fileListItemStyle}>
            {fileName}
          </li>
        ))}
      </ul>
    </div>
  );

  // Define dropdown labels
  const dropdownLabels = [
    "Transaction Date",
    "Customer Identifier",
    "Currency",
    "Payment Frequency",
    "Net Amount"
  ];

  // Handler for when a dropdown selection changes
  const handleDropdownChange = (index, value) => {
    const newSelections = [...dropdownSelections]; // creates new variable, and uses a spread operator (...) to create a copy of the existing dropdown array
    newSelections[index] = value; // updates the element at a specific index, assigns it a new value
    setDropdownSelections(newSelections); // Passes newSelections array into setDropdownSelections state setter function defined above
  };

  // Component to display NaN count per dropdown with percentage
  const MissingDataDisplay = ({ columnName }) => {
    if (!columnName || !columnStats[columnName]) return null;
    
    const stats = columnStats[columnName];
    const totalRows = combinedData.length;
    const percentage = totalRows > 0 ? ((stats.missingData.total / totalRows) * 100).toFixed(1) : 0;
    
    const displayStyle = {
      fontSize: '0.9em',
      color: percentage > 0 ? '#FF0000' : 
             '#28a745',
      marginTop: '5px',
      padding: '8px',
      borderRadius: '4px',
      backgroundColor: '#f8f9fa',
      border: '1px solid #dee2e6'
    };

    const breakdownStyle = {
      fontSize: '0.85em',
      marginTop: '5px',
      color: '#666',
      paddingLeft: '10px'
    };

    const { breakdown } = stats.missingData;
    const hasBreakdown = Object.values(breakdown).some(val => val > 0);

    return (
      <div style={displayStyle}>
        <div style={{ fontWeight: 'bold' }}>
          Missing values: {stats.missingData.total.toLocaleString()} ({percentage}%)
        </div>
        {hasBreakdown && (
          <div style={breakdownStyle}>
            <div style={{ marginBottom: '3px' }}>Breakdown:</div>
            <ul style={{ margin: '5px 0', paddingLeft: '20px' }}>
              {Object.entries(breakdown).map(([type, count]) => 
                count > 0 && (
                  <li key={type} style={{ marginBottom: '2px' }}>
                    {getBreakdownDescription(type)}: {count.toLocaleString()}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };
  
  // Handler for when the submit button is clicked
  const handleSubmit = async () => {
    setIsLoading(true); // Show the loader
    try {
      const response = await axios.post('/api/process', {
        csvData: combinedData,
        headers: headers,
        selections: dropdownSelections
      });
      
      // First set the chart data
      const metricsData = response.data.full_metrics;
      setChartData(metricsData);
      // Initialize display data with all chart data
      setDisplayData(metricsData);
      
      setPivotData(response.data.pivot_smoothed);

      setRecurringData(response.data.recurring_by_month);
      setNonRecurringData(response.data.non_recurring_by_month);

      setActiveTab('chart');
      setShowExportButton(true);

      // Add some debugging
      console.log('Response data:', response.data);
      console.log('Metrics data:', metricsData);

      setIsLoading(false); // Hide the loader
    } catch (error) {
      setIsLoading(false); // Hide the loader
      console.error('Error:', error);
      alert('An error occurred while processing the data.');
    }
  };

  // Handle the export to excel when the button is pressed
  const handleExport = async () => {
    setIsLoadingExport(true); // Show the loader
    try {
      // Send CSV data and dropdown selections to the backend
      // https://backend-fe-metrics-tool-a7307124d29f.herokuapp.com/process
      const response = await axios.post('/api/export', 
        { pivotData: pivotData }, // pivotData is sent as the request body in a separate object
        { // responseType and headers are moved to the axios config object (third parameter of axios.post)
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fe_metrics_output.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsLoadingExport(false); // hide the loader

    } catch (error) {
      setIsLoadingExport(false); // hide the loader
      console.error('Error:', error);
      alert('An error occurred while exporting the data.');
    }
  };

  // Handle the change in variable viewed on chart
  const handleVariableChange = (event) => {
    setSelectedVariable(event.target.value);
  };

  // useEffect hook to handle how data is displayed
  useEffect(() => {
    if (combinedData.length > 0 && headers.length > 0) {
      const stats = {};
      headers.forEach((header, index) => {
        stats[header] = {
          index: index,
          missingData: calculateMissingCount(index, combinedData)
        };
      });
      setColumnStats(stats);
    }
  }, [combinedData, headers, calculateMissingCount]);

  // Handle events happening after user selects date
  const handleDateChange = (event) => {
    const newDate = event.target.value === 'all' ? null : event.target.value;
    setSelectedDate(newDate);

    if (newDate && recurringData && nonRecurringData) {  // Use newDate instead of selectedDate
      const { pieChartData, stackedBarData } = calculateChartData(newDate);
      setPieChartData(pieChartData);
      setStackedBarData(stackedBarData);
    } else {
      setPieChartData(null);
      setStackedBarData(null);
    }
  };

  // Calculate pie chart values for LTM revenue by recurring or non-recurring
  const calculatePieChartData = (selectedIndex) => {
    const startIndex = Math.max(0, selectedIndex - 10);
    const endIndex = selectedIndex + 2;
    
    const recurringSum = recurringData.data
      .slice(startIndex, endIndex)
      .reduce((sum, item) => sum + item[1], 0);
    
    const nonRecurringSum = nonRecurringData.data
      .slice(startIndex, endIndex)
      .reduce((sum, item) => sum + item[1], 0);
    
    return [
      { name: 'Recurring', value: recurringSum },
      { name: 'Non-Recurring', value: nonRecurringSum }
    ];
  };

  // Calculate stacked bar chart values for LTM revenue by recurring or non-recurring
  const calculateStackedBarData = (selectedIndex) => {
    const data = [];
    let currentIndex = selectedIndex;

    while (currentIndex >= 0) {
      const endIndex = currentIndex + 2;
      const startIndex = Math.max(0, endIndex - 12);

      const recurringSum = recurringData.data
        .slice(startIndex, endIndex)
        .reduce((sum, item) => sum + item[1], 0);
      
      const nonRecurringSum = nonRecurringData.data
        .slice(startIndex, endIndex)
        .reduce((sum, item) => sum + item[1], 0);

      // Date range for x-axis tick marks
      const startDate = new Date(recurringData.data[startIndex][0]);
      const endDate = new Date(recurringData.data[endIndex - 1][0]);

      data.unshift({
        period: `${startDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}`,
        Recurring: recurringSum,
        'Non-Recurring': nonRecurringSum
      });

      currentIndex -= 12;

      if (startIndex === 0) break; // Stop if we've reached the beginning of the data
    }

    return data;
  };

  // Set values for both the pie chart and stacked bar chart data when selectedDate is set
  const calculateChartData = (selectedDate) => {
    const selectedIndex = recurringData.data.findIndex(item => item[0] === selectedDate);
    if (selectedIndex !== -1) {
      const pieChartData = calculatePieChartData(selectedIndex);
      const stackedBarData = calculateStackedBarData(selectedIndex);
      return { pieChartData, stackedBarData };
    }
    return { pieChartData: null, stackedBarData: null };
  };

  // Add useEffect to update displayData when chartData or selectedDate changes
  useEffect(() => {
    if (chartData) {
      if (selectedDate) {
        // Find the index of the selected date
        const dateIndex = chartData.findIndex(item => item.date === selectedDate);
        if (dateIndex !== -1) {
          // Get starting index (12 months before selected date)
          const startIndex = Math.max(0, dateIndex - 11);  // -11 because current month counts as 1
          // Slice the data to show only last 12 months up to selected date
          setDisplayData(chartData.slice(startIndex, dateIndex + 1));
        }
      } else {
        // If no date is selected, show all data
        setDisplayData(chartData);
      }
    }
  }, [chartData, selectedDate]);

  // Formatting for main line chart based on metric selection
  const formatValue = (value, variable) => {
    if (['MRR', 'Total MRR Churn', 'MRR Churn', 'ARR', 'ARPU', 'LTV'].includes(variable)) {
      return `$${Math.round(value).toLocaleString()}`;
    } else if (['MRR Churn Rate', 'Customer churn rate'].includes(variable)) {
      return `${(value * 100).toFixed(1)}%`;
    } else {
      return Math.round(value).toLocaleString();
    }
  };

  // Formatting for data labels of main line chart
  const CustomizedLabel = ({ x, y, value, index, variable }) => (
    <text 
      x={x} 
      y={y} 
      dy={-10} 
      fill="#666" 
      fontSize={10} 
      textAnchor="middle"
    >
      {formatValue(value, variable)}
    </text>
  );

  // Formatting of x-axis labels of main line chart
  const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text 
        y={0} 
        dy={16} 
        fill="#666" 
        fontSize={12}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </g>
  );

  // Format for stacked bar chart
  const formatDollar = (value) => {
    return `$${Math.round(value).toLocaleString()}`;
  };

  // Variables and colors to select form on chart
  const variables = ['MRR',
    'Total MRR Churn',
    'MRR Churn',
    'MRR Churn Rate',
    'Customers churned',
    'Total Customers',
    'Customer churn rate',
    'ARR',
    'ARPU',
    'LTV'
  ];

  const TableView = () => (
    <div style={tableContainerStyle}>
      <h2 style={mainViewheaderStyle}>Dataset Preview ({combinedData.length.toLocaleString()} total rows)</h2>
      <div style={tableSummaryStyle}>
        Showing first 15 rows of combined data from {uploadedFiles.length} file(s)
      </div>
      <table style={tableStyle}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} style={cellStyle}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {combinedData
            .slice(0, 15)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} style={cellStyle}>
                    {cell === null || cell === undefined || cell === '' ? '-' : cell}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  // Add a Clear All button component
  const ClearButton = () => (
    <button 
      onClick={() => {
        setCombinedData([]);
        setHeaders([]);
        setCsvData(null);
        setUploadedFiles([]);
        setColumnStats({});
        setShowExportButton(false);
        setChartData(null);
        setPivotData(null);
        setActiveTab('data');
      }}
      style={clearButtonStyle}
    >
      Clear All Files
    </button>
  );

  const LoadingIndicator = () => (
    <div style={loadingStyle}>
      Processing files... Please wait.
    </div>
  );

  // Return HTML page
  return (
    <div className="App" style={appStyle}>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {isDragActive ? 
          <p>Drop the CSV files here ...</p> : 
          <p>Drag and drop CSV files here, or click to select files</p>
        }
      </div>

      {isProcessing && <LoadingIndicator />}

      {uploadedFiles.length > 0 && (
        <div style={fileManagementStyle}>
          <FileList />
          <ClearButton />
        </div>
      )}
      
      {combinedData.length > 0 && headers.length > 0 && (
        <div style={contentContainerStyle}>
          <div style={sidebarStyle}>
            <h3 style={mainViewheaderStyle}>Column Verification</h3>
            <p style={standardLabelStyle}>
              Select the columns from your dataset that most closely align with the headers below.
            </p>
            {dropdownLabels.map((label, index) => (
              <div key={index} style={dropdownContainerStyle}>
                <label htmlFor={`dropdown-${index}`} style={labelStyle}>{label}</label>
                <select
                  id={`dropdown-${index}`}
                  value={dropdownSelections[index]}
                  onChange={(e) => handleDropdownChange(index, e.target.value)}
                  style={dropdownStyle}
                >
                  <option value="">Select a column</option>
                  {headers.map((header, headerIndex) => (
                    <option key={headerIndex} value={header}>{header}</option>
                  ))}
                </select>
                <MissingDataDisplay columnName={dropdownSelections[index]} />
              </div>
            ))}
            {isLoading && <div align="center"><Atom color="#32cd32" size="medium" text="Processing..." /></div>}
            <button onClick={handleSubmit} disabled={isLoading} style={buttonStyle}>Process Data</button>
            {showExportButton && (
              <>
                {isLoadingExport && <div align="center"><Atom color="#32cd32" size="medium" text="Exporting..." /></div>}
                <button 
                  onClick={handleExport} 
                  disabled={isLoadingExport}
                  style={{...buttonStyle, marginTop: '10px'}}
                >
                  Export to Excel
                </button>
              </>
            )}
          </div>

          <div style={mainContentStyle}>
            <div style={tabsStyle}>
              <button onClick={() => setActiveTab('data')} style={activeTab === 'data' ? activeTabStyle : tabStyle}>
                Data ({combinedData.length.toLocaleString()} rows)
              </button>
              <button onClick={() => setActiveTab('chart')} style={activeTab === 'chart' ? activeTabStyle : tabStyle}>
                Chart
              </button>
            </div>
            {activeTab === 'data' && <TableView />}
            
            {activeTab === 'chart' && chartData && (
              <div style={chartContainerStyle}>
                <div style={chartSelectionDropdownContainerStyle}>
                  <div style={chartSelectionDropdownWrapperStyle}>
                    <label htmlFor="variable-select" style={standardLabelStyle}>Choose a metric to display: </label>
                    <select 
                      id="variable-select" 
                      value={selectedVariable} 
                      onChange={handleVariableChange}
                      style={selectStyle}
                    >
                      {variables.map((variable) => (
                        <option key={variable} value={variable}>
                          {variable}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={chartSelectionDropdownWrapperStyle}>
                    <label htmlFor="date-select" style={standardLabelStyle}>Display metrics for last 12 months ending: </label>
                    <select 
                      id="date-select" 
                      value={selectedDate || 'all'} 
                      onChange={handleDateChange}
                      style={selectStyle}
                    >
                      <option value="all">Show All</option>
                      {chartData.map((item, index) => (
                        <option key={index} value={item.date}>
                          {item.date}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <h2 style={mainViewheaderStyle}>Metrics Chart - Displaying {selectedVariable}</h2>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={displayData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="date"
                      allowDataOverflow={true}
                      allowDecimals={false}
                      allowDuplicatedCategory={false}
                      tick={<CustomizedAxisTick />}
                      height={60}
                    />
                    <YAxis 
                      tickFormatter={(value) => formatValue(value, selectedVariable)}
                      tick={{ fontSize: 10 }}
                    >
                    </YAxis>
                    <Tooltip 
                      formatter={(value) => [formatValue(value, selectedVariable), selectedVariable]}
                    />
                    <Line
                      key={selectedVariable}
                      dataKey={selectedVariable}
                      type="monotone"
                      stroke="#8884d8"
                      activeDot={{r: 8}}
                      label={<CustomizedLabel variable={selectedVariable} />}
                    />
                  </LineChart>
                </ResponsiveContainer>

                {/* Pie Chart and Stacked Bar Chart beneath Main Line Chart*/}
                {pieChartData && stackedBarData && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                    {/* Pie Chart */}
                    <div style={{ width: '45%' }}>
                      <h3 style={mainViewheaderStyle}>LTM Revenue - Recurring vs Non-Recurring</h3>
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Pie
                            data={pieChartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#6487dc"
                            dataKey="value"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                          >
                            <Cell key="cell-0" fill="#6487dc" />
                            <Cell key="cell-1" fill="#808080" />
                          </Pie>
                          <Tooltip 
                            formatter={(value, name) => [formatDollar(value), name]}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>

                    {/* Stacked Bar Chart */}
                    <div style={{ width: '45%' }}>
                      <h3 style={mainViewheaderStyle}>LTM Revenue vs Prior Years</h3>
                      <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={stackedBarData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="period" 
                          height={80}
                          interval={0}
                          style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}
                        />
                        <YAxis 
                          tickFormatter={formatDollar}
                          style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}
                        />
                        <Tooltip 
                          formatter={(value, name) => [formatDollar(value), name]}
                        />
                        <Bar 
                          dataKey="Recurring" 
                          stackId="a" 
                          fill="#6487dc"
                        />
                        <Bar 
                          dataKey="Non-Recurring" 
                          stackId="a" 
                          fill="#808080"
                        />
                      </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}


// Styles
const appStyle = {
  fontFamily: 'Arial, sans-serif',
  padding: '20px',
  minHeight: '100vh',
};

const mainViewheaderStyle = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold',
  fontFamily: 'Arial, sans-serif',
  fontSize: '18px',
};

const standardLabelStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
  backgroundColor: '#FFFFFF',
  opacity: 0.9,
};

const contentContainerStyle = {
  display: 'flex',
  gap: '20px',
  opacity: 0.9,
};

const sidebarStyle = {
  width: '250px',
  padding: '20px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
};

const mainContentStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
};

const tabsStyle = {
  display: 'flex',
  marginBottom: '20px',
};

const tabStyle = {
  padding: '5px 20px',
  border: 'none',
  background: '#f0f0f0',
  cursor: 'pointer',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const activeTabStyle = {
  ...tabStyle,
  background: '#6487dc',
  color: 'white',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const dropdownContainerStyle = {
  marginBottom: '15px',
};

const labelStyle = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const dropdownStyle = {
  width: '100%',
  padding: '2px',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const buttonStyle = {
  width: '100%',
  padding: '10px 15px',
  fontFamily: 'Arial, sans-serif',
  fontSize: '16px',
  backgroundColor: '#6487dc',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginTop: '10px',
};

const tableContainerStyle = {
  overflowX: 'auto',
  backgroundColor: '#FFFFFF',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const cellStyle = {
  border: '1px solid #ddd',
  padding: '2px',
  textAlign: 'left',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
};

const chartContainerStyle = {
  height: '400px',
  width: '100%',
};

const fileListStyle = {
  marginTop: '10px',
  marginBottom: '20px',
  padding: '10px',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
  '& ul': {
    margin: '5px 0',
    paddingLeft: '20px',
  },
  '& li': {
    marginBottom: '3px',
  }
};

const loadingStyle = {
  textAlign: 'center',
  padding: '20px',
  backgroundColor: '#f8f9fa',
  border: '1px solid #dee2e6',
  borderRadius: '4px',
  margin: '10px 0',
  color: '#666',
};

const tableSummaryStyle = {
  marginBottom: '10px',
  color: '#666',
  fontSize: '0.9em',
  marginLeft: '20px',
};

const fileManagementStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '20px',
};

const fileListItemStyle = {
  marginBottom: '5px',
};

const clearButtonStyle = {
  backgroundColor: '#dc3545',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 16px',
  cursor: 'pointer',
  fontSize: '0.9em',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: '#c82333',
  },
};

const chartSelectionDropdownContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '20px',
};

const chartSelectionDropdownWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const selectStyle = {
  padding: '2px',
  width: '200px',
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};

export default Metrics;
