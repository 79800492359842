import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HoverableLink = ({ to, pageName, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      style={{
        ...styles.linkWrapper,
        ...(isHovered ? styles.linkWrapperHover : {})
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link 
        to={to} 
        style={{
          ...styles.link,
          ...(isHovered ? styles.linkHover : {})
        }}
      >
        {pageName}
      </Link>
      <p style={{
        ...styles.description,
        opacity: isHovered ? 1 : 0,
      }}>
        {description}
      </p>
    </div>
  );
};

const HomePage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.linkContainer}>
        <HoverableLink 
          to="/metrics" 
          pageName="SaaS Metrics" 
          description="Calculate SaaS Metrics like MRR, ARR, Churn, ARPU, LTV, and more."
        />
        <HoverableLink 
          to="/valuations" 
          pageName="Valuations" 
          description="Provides an indicative valuation range based on an analysis of comparable precedent transactions and public company."
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    minHeight: '100vh',
    padding: '1rem',
  },
  linkContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    maxWidth: '35%',
  },
  linkWrapper: {
    marginBottom: '1.5rem',
    padding: '1rem',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
  },
  linkWrapperHover: {
    border: '2px solid #1e40af',
    backgroundColor: '#f0f4ff',
  },
  link: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#000000',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    fontFamily: 'Arial, sans-serif',
  },
  linkHover: {
    color: '#1e40af',
  },
  description: {
    marginTop: '0.5rem',
    color: '#4b5563',
    transition: 'opacity 0.3s ease',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
  },
};

export default HomePage;