import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Toolbar from './Toolbar';
import Home from './Home';
import Metrics from './Metrics';
import Valuations from './Valuations';

function App() {
  return (
    <Router>
      <div className="app">
        <Toolbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Metrics" element={<Metrics />} />
            <Route path="/Valuations" element={<Valuations />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;