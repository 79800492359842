import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

function Toolbar() {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{...toolbarStyle, ...(isSticky ? stickyStyle : {})}}>
      <Link to="/" style={titleLinkStyle}>
        <h1 style={titleStyle}>FE International Inc. App Suite</h1>
      </Link>
      <select 
        onChange={(e) => navigate(e.target.value)} 
        style={dropdownStyle}
        value=""
      >
        <option value="" disabled>Select App</option>
        <option value="/">Home</option>
        <option value="/metrics">Metrics</option>
        <option value="/valuations">Valuations</option>
      </select>
    </div>
  );
}

const toolbarStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 20px',
  backgroundColor: '#FFFFFF',
  color: 'black',
  position: 'sticky',
  top: 0,
  zIndex: 1000,
};

const stickyStyle = {
  boxShadow: '0 2px 4px rgba(0,0,0,.1)',
};

const titleStyle = {
  fontFamily: 'Arial, sans-serif',
  margin: 0,
  fontSize: '24px',
  fontWeight: '500',
};

const titleLinkStyle = {
  textDecoration: 'none',
  color: 'inherit',
};

const dropdownStyle = {
  fontFamily: 'Arial, sans-serif',
  padding: '5px',
  fontSize: '16px',
};

export default Toolbar;